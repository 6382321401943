/* tslint:disable */
/* eslint-disable */
/**
 * TrustAdminAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Bigram
 */
export interface Bigram {
    /**
     * 
     * @type {string}
     * @memberof Bigram
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof Bigram
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface BulkListsUserSummaryRequest
 */
export interface BulkListsUserSummaryRequest {
    /**
     * List of User Ids (uconst)
     * @type {Array<string>}
     * @memberof BulkListsUserSummaryRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface BulkUserStatusUpdaterRequest
 */
export interface BulkUserStatusUpdaterRequest {
    /**
     * List of CustomerIds to Shadowban
     * @type {Array<string>}
     * @memberof BulkUserStatusUpdaterRequest
     */
    'customerIds': Array<string>;
    /**
     * Shadowban Context such as tconst, lconst, reviewId etc.
     * @type {string}
     * @memberof BulkUserStatusUpdaterRequest
     */
    'shadowbanContext': string;
    /**
     * 
     * @type {FeatureType}
     * @memberof BulkUserStatusUpdaterRequest
     */
    'feature': FeatureType;
}
/**
 * 
 * @export
 * @interface BulkUserStatusUpdaterResponse
 */
export interface BulkUserStatusUpdaterResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUserStatusUpdaterResponse
     */
    'trustedCustomerIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BulkUserStatusUpdaterStatusMetadata
 */
export interface BulkUserStatusUpdaterStatusMetadata {
    /**
     * 
     * @type {string}
     * @memberof BulkUserStatusUpdaterStatusMetadata
     */
    'bucketName': string;
    /**
     * 
     * @type {string}
     * @memberof BulkUserStatusUpdaterStatusMetadata
     */
    'requestingUser': string;
    /**
     * 
     * @type {string}
     * @memberof BulkUserStatusUpdaterStatusMetadata
     */
    'feature': string;
    /**
     * 
     * @type {number}
     * @memberof BulkUserStatusUpdaterStatusMetadata
     */
    'successes'?: number;
    /**
     * 
     * @type {string}
     * @memberof BulkUserStatusUpdaterStatusMetadata
     */
    'context': string;
    /**
     * 
     * @type {number}
     * @memberof BulkUserStatusUpdaterStatusMetadata
     */
    'overrides'?: number;
    /**
     * 
     * @type {string}
     * @memberof BulkUserStatusUpdaterStatusMetadata
     */
    'queryExecutionId': string;
    /**
     * 
     * @type {number}
     * @memberof BulkUserStatusUpdaterStatusMetadata
     */
    'faults'?: number;
}
/**
 * 
 * @export
 * @interface BulkUserStatusUpdaterStatusResponse
 */
export interface BulkUserStatusUpdaterStatusResponse {
    /**
     * 
     * @type {Array<UserStatusUpdaterStatus>}
     * @memberof BulkUserStatusUpdaterStatusResponse
     */
    'statuses': Array<UserStatusUpdaterStatus>;
}
/**
 * 
 * @export
 * @interface ChartSummary
 */
export interface ChartSummary {
    /**
     * 
     * @type {string}
     * @memberof ChartSummary
     */
    'chartName': string;
    /**
     * 
     * @type {number}
     * @memberof ChartSummary
     */
    'rating': number;
    /**
     * 
     * @type {number}
     * @memberof ChartSummary
     */
    'position': number;
}
/**
 * 
 * @export
 * @interface CreatedListsDistribution
 */
export interface CreatedListsDistribution {
    /**
     * 
     * @type {string}
     * @memberof CreatedListsDistribution
     */
    'createdDay': string;
    /**
     * 
     * @type {number}
     * @memberof CreatedListsDistribution
     */
    'count': number;
}
/**
 * Feature such as Ratings, Reviews, Lists, Others.
 * @export
 * @enum {string}
 */

export enum FeatureType {
    Ratings = 'ratings',
    UserReviews = 'user_reviews',
    UserLists = 'user_lists',
    EmailDomain = 'email_domain',
    BartletVoting = 'bartlet_voting',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface FrequentlyUsedText
 */
export interface FrequentlyUsedText {
    /**
     * 
     * @type {string}
     * @memberof FrequentlyUsedText
     */
    'text': string;
    /**
     * the number of times the text was used
     * @type {number}
     * @memberof FrequentlyUsedText
     */
    'occurrences': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrequentlyUsedText
     */
    'users': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum JobStatus {
    Succeeded = 'SUCCEEDED',
    Running = 'RUNNING',
    Queued = 'QUEUED',
    Failed = 'FAILED',
    Cancelled = 'CANCELLED',
    Unmapped = 'UNMAPPED'
}

/**
 * 
 * @export
 * @interface ListsActivityRequest
 */
export interface ListsActivityRequest {
    /**
     * beginning of the time range
     * @type {number}
     * @memberof ListsActivityRequest
     */
    'startTime': number;
    /**
     * end of the time range
     * @type {number}
     * @memberof ListsActivityRequest
     */
    'endTime': number;
    /**
     * 
     * @type {string}
     * @memberof ListsActivityRequest
     */
    'spamFilter': string;
    /**
     * page size, max results to be returned
     * @type {number}
     * @memberof ListsActivityRequest
     */
    'maxResults': number | null;
    /**
     * token to get the next page of items
     * @type {string}
     * @memberof ListsActivityRequest
     */
    'nextToken': string | null;
}
/**
 * 
 * @export
 * @interface ListsOwnersError
 */
export interface ListsOwnersError {
    /**
     * customerId or listId
     * @type {string}
     * @memberof ListsOwnersError
     */
    'id': string;
    /**
     * error code
     * @type {string}
     * @memberof ListsOwnersError
     */
    'code': string;
    /**
     * error message
     * @type {string}
     * @memberof ListsOwnersError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ListsOwnersRequest
 */
export interface ListsOwnersRequest {
    /**
     * List of CustomerIds or ListIds
     * @type {Array<string>}
     * @memberof ListsOwnersRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface ListsOwnersResponse
 */
export interface ListsOwnersResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListsOwnersResponse
     */
    'customerIds': Array<string>;
    /**
     * 
     * @type {Array<ListsOwnersError>}
     * @memberof ListsOwnersResponse
     */
    'errors': Array<ListsOwnersError>;
}
/**
 * 
 * @export
 * @interface ListsTextItemsResponse
 */
export interface ListsTextItemsResponse {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ListsTextItemsResponse
     */
    'frequentlyUsedText': { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ListsTextItemsResponse
     */
    'nextToken': string | null;
}
/**
 * 
 * @export
 * @interface ListsTextSearchDetailsRequest
 */
export interface ListsTextSearchDetailsRequest {
    /**
     * beginning of the time range
     * @type {number}
     * @memberof ListsTextSearchDetailsRequest
     */
    'startTime': number;
    /**
     * end of the time range
     * @type {number}
     * @memberof ListsTextSearchDetailsRequest
     */
    'endTime': number;
    /**
     * 
     * @type {string}
     * @memberof ListsTextSearchDetailsRequest
     */
    'spamFilter'?: string;
    /**
     * page size, max results to be returned
     * @type {number}
     * @memberof ListsTextSearchDetailsRequest
     */
    'maxResults'?: number;
    /**
     * token to get the next page of items
     * @type {string}
     * @memberof ListsTextSearchDetailsRequest
     */
    'nextToken'?: string;
}
/**
 * 
 * @export
 * @interface ListsTextSearchDetailsResponse
 */
export interface ListsTextSearchDetailsResponse {
    /**
     * 
     * @type {Array<FrequentlyUsedText>}
     * @memberof ListsTextSearchDetailsResponse
     */
    'frequentlyUsedText': Array<FrequentlyUsedText>;
    /**
     * 
     * @type {Array<UserActivityItem>}
     * @memberof ListsTextSearchDetailsResponse
     */
    'userActivity': Array<UserActivityItem>;
    /**
     * 
     * @type {string}
     * @memberof ListsTextSearchDetailsResponse
     */
    'nextToken': string | null;
}
/**
 * 
 * @export
 * @interface ListsUserActivityResponse
 */
export interface ListsUserActivityResponse {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ListsUserActivityResponse
     */
    'userActivity': { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ListsUserActivityResponse
     */
    'nextToken': string | null;
}
/**
 * 
 * @export
 * @interface ListsUserSummary
 */
export interface ListsUserSummary {
    /**
     * 
     * @type {UserAttributes}
     * @memberof ListsUserSummary
     */
    'userAttributes': UserAttributes;
    /**
     * 
     * @type {ListsUserSummaryListsCreated}
     * @memberof ListsUserSummary
     */
    'listsCreated': ListsUserSummaryListsCreated;
    /**
     * top bigrams used in list free text
     * @type {Array<Bigram>}
     * @memberof ListsUserSummary
     */
    'bigrams': Array<Bigram>;
}
/**
 * the distribution of lists created per day by the user
 * @export
 * @interface ListsUserSummaryListsCreated
 */
export interface ListsUserSummaryListsCreated {
    /**
     * 
     * @type {Array<CreatedListsDistribution>}
     * @memberof ListsUserSummaryListsCreated
     */
    'lists': Array<CreatedListsDistribution>;
    /**
     * 
     * @type {CreatedListsDistribution}
     * @memberof ListsUserSummaryListsCreated
     */
    'maxVelocity': CreatedListsDistribution;
    /**
     * 
     * @type {ListsUserSummaryListsCreatedOverall}
     * @memberof ListsUserSummaryListsCreated
     */
    'overall': ListsUserSummaryListsCreatedOverall;
}
/**
 * 
 * @export
 * @interface ListsUserSummaryListsCreatedOverall
 */
export interface ListsUserSummaryListsCreatedOverall {
    /**
     * 
     * @type {number}
     * @memberof ListsUserSummaryListsCreatedOverall
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ListsUserSummaryListsCreatedOverall
     */
    'publicCount': number;
    /**
     * 
     * @type {number}
     * @memberof ListsUserSummaryListsCreatedOverall
     */
    'restrictedCount': number;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface RatingsExportPageResponse
 */
export interface RatingsExportPageResponse {
    /**
     * 
     * @type {Array<RatingsExportRow>}
     * @memberof RatingsExportPageResponse
     */
    'rows': Array<RatingsExportRow>;
    /**
     * 
     * @type {string}
     * @memberof RatingsExportPageResponse
     */
    'next'?: string;
}
/**
 * 
 * @export
 * @interface RatingsExportRow
 */
export interface RatingsExportRow {
    /**
     * 
     * @type {string}
     * @memberof RatingsExportRow
     */
    'customerId': string;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof RatingsExportRow
     */
    'dateRated': string;
    /**
     * 
     * @type {boolean}
     * @memberof RatingsExportRow
     */
    'isShadowbanned'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RatingsExportRow
     */
    'countryCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RatingsExportRow
     */
    'isStaff'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RatingsExportRow
     */
    'signupDatetime'?: string;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'timediffHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof RatingsExportRow
     */
    'lastDay'?: string;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'numRatings': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'weight': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'rAvg': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_1': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_2': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_3': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_4': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_5': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_6': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_7': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_8': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_9': number;
    /**
     * 
     * @type {number}
     * @memberof RatingsExportRow
     */
    'r_10': number;
    /**
     * 
     * @type {string}
     * @memberof RatingsExportRow
     */
    'emailDomain'?: string;
    /**
     * 
     * @type {string}
     * @memberof RatingsExportRow
     */
    'emailDomainObfuscated'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SupportedChartType {
    Top = 'Top',
    TopTv = 'TopTv',
    IndiaTop = 'IndiaTop',
    EnglishTop = 'EnglishTop',
    MalayalamTop = 'MalayalamTop',
    TamilTop = 'TamilTop',
    TeluguTop = 'TeluguTop',
    Bottom = 'Bottom'
}

/**
 * 
 * @export
 * @interface SuspiciousTitle
 */
export interface SuspiciousTitle {
    /**
     * 
     * @type {string}
     * @memberof SuspiciousTitle
     */
    'tconst': string;
    /**
     * 
     * @type {string}
     * @memberof SuspiciousTitle
     */
    'titleText': string;
    /**
     * 
     * @type {number}
     * @memberof SuspiciousTitle
     */
    'buildChangeCount': number;
    /**
     * 
     * @type {number}
     * @memberof SuspiciousTitle
     */
    'buildChangeStddev': number;
    /**
     * 
     * @type {number}
     * @memberof SuspiciousTitle
     */
    'dayChangeCount': number;
    /**
     * 
     * @type {number}
     * @memberof SuspiciousTitle
     */
    'dayChangeStddev': number;
    /**
     * 
     * @type {number}
     * @memberof SuspiciousTitle
     */
    'weekChangeCount': number;
    /**
     * 
     * @type {number}
     * @memberof SuspiciousTitle
     */
    'weekChangeStddev': number;
    /**
     * 
     * @type {number}
     * @memberof SuspiciousTitle
     */
    'titleCount': number;
}
/**
 * 
 * @export
 * @interface SuspiciousTitlesDataExport
 */
export interface SuspiciousTitlesDataExport {
    /**
     * 
     * @type {string}
     * @memberof SuspiciousTitlesDataExport
     */
    'downloadUrl': string;
}
/**
 * 
 * @export
 * @interface SuspiciousTitlesOutput
 */
export interface SuspiciousTitlesOutput {
    /**
     * 
     * @type {Array<SuspiciousTitle>}
     * @memberof SuspiciousTitlesOutput
     */
    'suspiciousTitles': Array<SuspiciousTitle>;
    /**
     * 
     * @type {string}
     * @memberof SuspiciousTitlesOutput
     */
    'lastBuildDateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof SuspiciousTitlesOutput
     */
    'numUniqueTitles': number;
}
/**
 * 
 * @export
 * @interface TitleDetailsResponse
 */
export interface TitleDetailsResponse {
    /**
     * 
     * @type {TitleDetailsResponseRatingsDetails}
     * @memberof TitleDetailsResponse
     */
    'ratingsDetails': TitleDetailsResponseRatingsDetails;
}
/**
 * 
 * @export
 * @interface TitleDetailsResponseRatingsDetails
 */
export interface TitleDetailsResponseRatingsDetails {
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetails
     */
    'ratingsBuildValue'?: number;
    /**
     * 
     * @type {TitleDetailsResponseRatingsDetailsRatingsCount}
     * @memberof TitleDetailsResponseRatingsDetails
     */
    'ratingsCount'?: TitleDetailsResponseRatingsDetailsRatingsCount;
    /**
     * 
     * @type {TitleDetailsResponseRatingsDetailsStandardDeviation}
     * @memberof TitleDetailsResponseRatingsDetails
     */
    'standardDeviation': TitleDetailsResponseRatingsDetailsStandardDeviation;
    /**
     * 
     * @type {TitleDetailsResponseRatingsDetailsRevisedRatings}
     * @memberof TitleDetailsResponseRatingsDetails
     */
    'revisedRatings'?: TitleDetailsResponseRatingsDetailsRevisedRatings;
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetails
     */
    'shadowbannedRatingsCount'?: number;
    /**
     * 
     * @type {Array<ChartSummary>}
     * @memberof TitleDetailsResponseRatingsDetails
     */
    'charts'?: Array<ChartSummary>;
}
/**
 * 
 * @export
 * @interface TitleDetailsResponseRatingsDetailsRatingsCount
 */
export interface TitleDetailsResponseRatingsDetailsRatingsCount {
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetailsRatingsCount
     */
    'lastBuildValue': number | null;
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetailsRatingsCount
     */
    'buildPercentageChange': number | null;
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetailsRatingsCount
     */
    'dayPercentageChange': number | null;
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetailsRatingsCount
     */
    'weekPercentageChange': number | null;
}
/**
 * 
 * @export
 * @interface TitleDetailsResponseRatingsDetailsRevisedRatings
 */
export interface TitleDetailsResponseRatingsDetailsRevisedRatings {
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetailsRevisedRatings
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof TitleDetailsResponseRatingsDetailsRevisedRatings
     */
    'approver': string;
    /**
     * 
     * @type {string}
     * @memberof TitleDetailsResponseRatingsDetailsRevisedRatings
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface TitleDetailsResponseRatingsDetailsStandardDeviation
 */
export interface TitleDetailsResponseRatingsDetailsStandardDeviation {
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetailsStandardDeviation
     */
    'lastBuildValue': number | null;
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetailsStandardDeviation
     */
    'buildPercentageChange': number | null;
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetailsStandardDeviation
     */
    'dayPercentageChange': number | null;
    /**
     * 
     * @type {number}
     * @memberof TitleDetailsResponseRatingsDetailsStandardDeviation
     */
    'weekPercentageChange': number | null;
}
/**
 * 
 * @export
 * @interface TitleRatingsExportLogRequest
 */
export interface TitleRatingsExportLogRequest {
    /**
     * 
     * @type {string}
     * @memberof TitleRatingsExportLogRequest
     */
    'tconst': string;
    /**
     * 
     * @type {string}
     * @memberof TitleRatingsExportLogRequest
     */
    'lastBuildDateTime': string;
}
/**
 * 
 * @export
 * @interface TitleRatingsExportLogResponse
 */
export interface TitleRatingsExportLogResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TitleRatingsExportLogResponse
     */
    'succeeded': boolean;
    /**
     * 
     * @type {string}
     * @memberof TitleRatingsExportLogResponse
     */
    'tconst': string;
    /**
     * 
     * @type {string}
     * @memberof TitleRatingsExportLogResponse
     */
    'lastBuildDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof TitleRatingsExportLogResponse
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof TitleRatingsExportLogResponse
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface TitleRatingsExportRequestResponse
 */
export interface TitleRatingsExportRequestResponse {
    /**
     * 
     * @type {string}
     * @memberof TitleRatingsExportRequestResponse
     */
    'exportToken': string;
}
/**
 * 
 * @export
 * @interface TitleRatingsExportStatusResponse
 */
export interface TitleRatingsExportStatusResponse {
    /**
     * 
     * @type {JobStatus}
     * @memberof TitleRatingsExportStatusResponse
     */
    'status': JobStatus;
    /**
     * 
     * @type {string}
     * @memberof TitleRatingsExportStatusResponse
     */
    'lastBuildDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleRatingsExportStatusResponse
     */
    'downloadUrl'?: string;
    /**
     * 
     * @type {RatingsExportPageResponse}
     * @memberof TitleRatingsExportStatusResponse
     */
    'ratings'?: RatingsExportPageResponse;
}
/**
 * 
 * @export
 * @interface UserActivityItem
 */
export interface UserActivityItem {
    /**
     * 
     * @type {string}
     * @memberof UserActivityItem
     */
    'customerId': string;
    /**
     * number of lists modified by this user
     * @type {number}
     * @memberof UserActivityItem
     */
    'modifiedListCount': number;
}
/**
 * 
 * @export
 * @interface UserAttributes
 */
export interface UserAttributes {
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    'nickName': string;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    'status': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    'isPro': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    'registeredDay': string;
}
/**
 * 
 * @export
 * @interface UserStatusUpdaterStatus
 */
export interface UserStatusUpdaterStatus {
    /**
     * 
     * @type {string}
     * @memberof UserStatusUpdaterStatus
     */
    'requestId': string;
    /**
     * 
     * @type {JobStatus}
     * @memberof UserStatusUpdaterStatus
     */
    'status': JobStatus;
    /**
     * 
     * @type {BulkUserStatusUpdaterStatusMetadata}
     * @memberof UserStatusUpdaterStatus
     */
    'metadata': BulkUserStatusUpdaterStatusMetadata;
    /**
     * 
     * @type {string}
     * @memberof UserStatusUpdaterStatus
     */
    'created': string;
}

/**
 * BulkGetListsUserSummaryApi - axios parameter creator
 * @export
 */
export const BulkGetListsUserSummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary returns a list of user summary with user attributes and their most recent lists
         * @param {BulkListsUserSummaryRequest} bulkListsUserSummaryRequest A task to get a list of user attributes and their most recent lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkGetListsUserSummary: async (bulkListsUserSummaryRequest: BulkListsUserSummaryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkListsUserSummaryRequest' is not null or undefined
            assertParamExists('bulkGetListsUserSummary', 'bulkListsUserSummaryRequest', bulkListsUserSummaryRequest)
            const localVarPath = `/lists/UserSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkListsUserSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkGetListsUserSummaryApi - functional programming interface
 * @export
 */
export const BulkGetListsUserSummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkGetListsUserSummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary returns a list of user summary with user attributes and their most recent lists
         * @param {BulkListsUserSummaryRequest} bulkListsUserSummaryRequest A task to get a list of user attributes and their most recent lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkGetListsUserSummary(bulkListsUserSummaryRequest: BulkListsUserSummaryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListsUserSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkGetListsUserSummary(bulkListsUserSummaryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulkGetListsUserSummaryApi - factory interface
 * @export
 */
export const BulkGetListsUserSummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkGetListsUserSummaryApiFp(configuration)
    return {
        /**
         * 
         * @summary returns a list of user summary with user attributes and their most recent lists
         * @param {BulkListsUserSummaryRequest} bulkListsUserSummaryRequest A task to get a list of user attributes and their most recent lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkGetListsUserSummary(bulkListsUserSummaryRequest: BulkListsUserSummaryRequest, options?: any): AxiosPromise<Array<ListsUserSummary>> {
            return localVarFp.bulkGetListsUserSummary(bulkListsUserSummaryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkGetListsUserSummaryApi - object-oriented interface
 * @export
 * @class BulkGetListsUserSummaryApi
 * @extends {BaseAPI}
 */
export class BulkGetListsUserSummaryApi extends BaseAPI {
    /**
     * 
     * @summary returns a list of user summary with user attributes and their most recent lists
     * @param {BulkListsUserSummaryRequest} bulkListsUserSummaryRequest A task to get a list of user attributes and their most recent lists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkGetListsUserSummaryApi
     */
    public bulkGetListsUserSummary(bulkListsUserSummaryRequest: BulkListsUserSummaryRequest, options?: AxiosRequestConfig) {
        return BulkGetListsUserSummaryApiFp(this.configuration).bulkGetListsUserSummary(bulkListsUserSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BulkUpdateUserStatusApi - axios parameter creator
 * @export
 */
export const BulkUpdateUserStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk Update User Status
         * @param {BulkUserStatusUpdaterRequest} bulkUserStatusUpdaterRequest A status update task for shadowbanning and killfiling users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUserStatus: async (bulkUserStatusUpdaterRequest: BulkUserStatusUpdaterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUserStatusUpdaterRequest' is not null or undefined
            assertParamExists('bulkUpdateUserStatus', 'bulkUserStatusUpdaterRequest', bulkUserStatusUpdaterRequest)
            const localVarPath = `/updateUserStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUserStatusUpdaterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkUpdateUserStatusApi - functional programming interface
 * @export
 */
export const BulkUpdateUserStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkUpdateUserStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bulk Update User Status
         * @param {BulkUserStatusUpdaterRequest} bulkUserStatusUpdaterRequest A status update task for shadowbanning and killfiling users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateUserStatus(bulkUserStatusUpdaterRequest: BulkUserStatusUpdaterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkUserStatusUpdaterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateUserStatus(bulkUserStatusUpdaterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulkUpdateUserStatusApi - factory interface
 * @export
 */
export const BulkUpdateUserStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkUpdateUserStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary Bulk Update User Status
         * @param {BulkUserStatusUpdaterRequest} bulkUserStatusUpdaterRequest A status update task for shadowbanning and killfiling users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUserStatus(bulkUserStatusUpdaterRequest: BulkUserStatusUpdaterRequest, options?: any): AxiosPromise<BulkUserStatusUpdaterResponse> {
            return localVarFp.bulkUpdateUserStatus(bulkUserStatusUpdaterRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkUpdateUserStatusApi - object-oriented interface
 * @export
 * @class BulkUpdateUserStatusApi
 * @extends {BaseAPI}
 */
export class BulkUpdateUserStatusApi extends BaseAPI {
    /**
     * 
     * @summary Bulk Update User Status
     * @param {BulkUserStatusUpdaterRequest} bulkUserStatusUpdaterRequest A status update task for shadowbanning and killfiling users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkUpdateUserStatusApi
     */
    public bulkUpdateUserStatus(bulkUserStatusUpdaterRequest: BulkUserStatusUpdaterRequest, options?: AxiosRequestConfig) {
        return BulkUpdateUserStatusApiFp(this.configuration).bulkUpdateUserStatus(bulkUserStatusUpdaterRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BulkUpdateUserStatusStatusApi - axios parameter creator
 * @export
 */
export const BulkUpdateUserStatusStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Query current statuses for bulk shadowban jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUserStatusStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/updateUserStatus/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkUpdateUserStatusStatusApi - functional programming interface
 * @export
 */
export const BulkUpdateUserStatusStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkUpdateUserStatusStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Query current statuses for bulk shadowban jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateUserStatusStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkUserStatusUpdaterStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateUserStatusStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulkUpdateUserStatusStatusApi - factory interface
 * @export
 */
export const BulkUpdateUserStatusStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkUpdateUserStatusStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary Query current statuses for bulk shadowban jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUserStatusStatus(options?: any): AxiosPromise<BulkUserStatusUpdaterStatusResponse> {
            return localVarFp.bulkUpdateUserStatusStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkUpdateUserStatusStatusApi - object-oriented interface
 * @export
 * @class BulkUpdateUserStatusStatusApi
 * @extends {BaseAPI}
 */
export class BulkUpdateUserStatusStatusApi extends BaseAPI {
    /**
     * 
     * @summary Query current statuses for bulk shadowban jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkUpdateUserStatusStatusApi
     */
    public bulkUpdateUserStatusStatus(options?: AxiosRequestConfig) {
        return BulkUpdateUserStatusStatusApiFp(this.configuration).bulkUpdateUserStatusStatus(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetListsOwnersApi - axios parameter creator
 * @export
 */
export const GetListsOwnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary take a batch of customerIds or listIds and consolidate into customerIds by fetching list owners
         * @param {ListsOwnersRequest} listsOwnersRequest A task to consolidate a batch of customerIds and listIds into customerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsOwners: async (listsOwnersRequest: ListsOwnersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listsOwnersRequest' is not null or undefined
            assertParamExists('getListsOwners', 'listsOwnersRequest', listsOwnersRequest)
            const localVarPath = `/lists/getListsOwners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listsOwnersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetListsOwnersApi - functional programming interface
 * @export
 */
export const GetListsOwnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetListsOwnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary take a batch of customerIds or listIds and consolidate into customerIds by fetching list owners
         * @param {ListsOwnersRequest} listsOwnersRequest A task to consolidate a batch of customerIds and listIds into customerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListsOwners(listsOwnersRequest: ListsOwnersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListsOwnersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListsOwners(listsOwnersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetListsOwnersApi - factory interface
 * @export
 */
export const GetListsOwnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetListsOwnersApiFp(configuration)
    return {
        /**
         * 
         * @summary take a batch of customerIds or listIds and consolidate into customerIds by fetching list owners
         * @param {ListsOwnersRequest} listsOwnersRequest A task to consolidate a batch of customerIds and listIds into customerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsOwners(listsOwnersRequest: ListsOwnersRequest, options?: any): AxiosPromise<ListsOwnersResponse> {
            return localVarFp.getListsOwners(listsOwnersRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetListsOwnersApi - object-oriented interface
 * @export
 * @class GetListsOwnersApi
 * @extends {BaseAPI}
 */
export class GetListsOwnersApi extends BaseAPI {
    /**
     * 
     * @summary take a batch of customerIds or listIds and consolidate into customerIds by fetching list owners
     * @param {ListsOwnersRequest} listsOwnersRequest A task to consolidate a batch of customerIds and listIds into customerIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetListsOwnersApi
     */
    public getListsOwners(listsOwnersRequest: ListsOwnersRequest, options?: AxiosRequestConfig) {
        return GetListsOwnersApiFp(this.configuration).getListsOwners(listsOwnersRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetListsTextItemsApi - axios parameter creator
 * @export
 */
export const GetListsTextItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary returns an aggregated frequently used text in lists modified within a time window
         * @param {ListsActivityRequest} listsActivityRequest A task to collect all list text items for lists modified in a given time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsTextItems: async (listsActivityRequest: ListsActivityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listsActivityRequest' is not null or undefined
            assertParamExists('getListsTextItems', 'listsActivityRequest', listsActivityRequest)
            const localVarPath = `/lists/textItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listsActivityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetListsTextItemsApi - functional programming interface
 * @export
 */
export const GetListsTextItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetListsTextItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary returns an aggregated frequently used text in lists modified within a time window
         * @param {ListsActivityRequest} listsActivityRequest A task to collect all list text items for lists modified in a given time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListsTextItems(listsActivityRequest: ListsActivityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListsTextItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListsTextItems(listsActivityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetListsTextItemsApi - factory interface
 * @export
 */
export const GetListsTextItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetListsTextItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary returns an aggregated frequently used text in lists modified within a time window
         * @param {ListsActivityRequest} listsActivityRequest A task to collect all list text items for lists modified in a given time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsTextItems(listsActivityRequest: ListsActivityRequest, options?: any): AxiosPromise<ListsTextItemsResponse> {
            return localVarFp.getListsTextItems(listsActivityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetListsTextItemsApi - object-oriented interface
 * @export
 * @class GetListsTextItemsApi
 * @extends {BaseAPI}
 */
export class GetListsTextItemsApi extends BaseAPI {
    /**
     * 
     * @summary returns an aggregated frequently used text in lists modified within a time window
     * @param {ListsActivityRequest} listsActivityRequest A task to collect all list text items for lists modified in a given time range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetListsTextItemsApi
     */
    public getListsTextItems(listsActivityRequest: ListsActivityRequest, options?: AxiosRequestConfig) {
        return GetListsTextItemsApiFp(this.configuration).getListsTextItems(listsActivityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetListsTextSearchDetailsApi - axios parameter creator
 * @export
 */
export const GetListsTextSearchDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary returns an aggregated response of most frequently used phrases and most active users for lists modified within a time window
         * @param {ListsTextSearchDetailsRequest} listsTextSearchDetailsRequest A task to collect all list text items for lists modified in a given time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsTextSearchDetails: async (listsTextSearchDetailsRequest: ListsTextSearchDetailsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listsTextSearchDetailsRequest' is not null or undefined
            assertParamExists('getListsTextSearchDetails', 'listsTextSearchDetailsRequest', listsTextSearchDetailsRequest)
            const localVarPath = `/lists/textSearchDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listsTextSearchDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetListsTextSearchDetailsApi - functional programming interface
 * @export
 */
export const GetListsTextSearchDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetListsTextSearchDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary returns an aggregated response of most frequently used phrases and most active users for lists modified within a time window
         * @param {ListsTextSearchDetailsRequest} listsTextSearchDetailsRequest A task to collect all list text items for lists modified in a given time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListsTextSearchDetails(listsTextSearchDetailsRequest: ListsTextSearchDetailsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListsTextSearchDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListsTextSearchDetails(listsTextSearchDetailsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetListsTextSearchDetailsApi - factory interface
 * @export
 */
export const GetListsTextSearchDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetListsTextSearchDetailsApiFp(configuration)
    return {
        /**
         * 
         * @summary returns an aggregated response of most frequently used phrases and most active users for lists modified within a time window
         * @param {ListsTextSearchDetailsRequest} listsTextSearchDetailsRequest A task to collect all list text items for lists modified in a given time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsTextSearchDetails(listsTextSearchDetailsRequest: ListsTextSearchDetailsRequest, options?: any): AxiosPromise<ListsTextSearchDetailsResponse> {
            return localVarFp.getListsTextSearchDetails(listsTextSearchDetailsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetListsTextSearchDetailsApi - object-oriented interface
 * @export
 * @class GetListsTextSearchDetailsApi
 * @extends {BaseAPI}
 */
export class GetListsTextSearchDetailsApi extends BaseAPI {
    /**
     * 
     * @summary returns an aggregated response of most frequently used phrases and most active users for lists modified within a time window
     * @param {ListsTextSearchDetailsRequest} listsTextSearchDetailsRequest A task to collect all list text items for lists modified in a given time range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetListsTextSearchDetailsApi
     */
    public getListsTextSearchDetails(listsTextSearchDetailsRequest: ListsTextSearchDetailsRequest, options?: AxiosRequestConfig) {
        return GetListsTextSearchDetailsApiFp(this.configuration).getListsTextSearchDetails(listsTextSearchDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetListsUserActivityApi - axios parameter creator
 * @export
 */
export const GetListsUserActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary returns an aggregated user activity for lists modified within a time window
         * @param {ListsActivityRequest} listsActivityRequest A task to collect all update operations for lists modified in a given time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsUserActivity: async (listsActivityRequest: ListsActivityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listsActivityRequest' is not null or undefined
            assertParamExists('getListsUserActivity', 'listsActivityRequest', listsActivityRequest)
            const localVarPath = `/lists/userActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listsActivityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetListsUserActivityApi - functional programming interface
 * @export
 */
export const GetListsUserActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetListsUserActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary returns an aggregated user activity for lists modified within a time window
         * @param {ListsActivityRequest} listsActivityRequest A task to collect all update operations for lists modified in a given time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListsUserActivity(listsActivityRequest: ListsActivityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListsUserActivityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListsUserActivity(listsActivityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetListsUserActivityApi - factory interface
 * @export
 */
export const GetListsUserActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetListsUserActivityApiFp(configuration)
    return {
        /**
         * 
         * @summary returns an aggregated user activity for lists modified within a time window
         * @param {ListsActivityRequest} listsActivityRequest A task to collect all update operations for lists modified in a given time range
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListsUserActivity(listsActivityRequest: ListsActivityRequest, options?: any): AxiosPromise<ListsUserActivityResponse> {
            return localVarFp.getListsUserActivity(listsActivityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetListsUserActivityApi - object-oriented interface
 * @export
 * @class GetListsUserActivityApi
 * @extends {BaseAPI}
 */
export class GetListsUserActivityApi extends BaseAPI {
    /**
     * 
     * @summary returns an aggregated user activity for lists modified within a time window
     * @param {ListsActivityRequest} listsActivityRequest A task to collect all update operations for lists modified in a given time range
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetListsUserActivityApi
     */
    public getListsUserActivity(listsActivityRequest: ListsActivityRequest, options?: AxiosRequestConfig) {
        return GetListsUserActivityApiFp(this.configuration).getListsUserActivity(listsActivityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuspiciousTitlesExportDataApi - axios parameter creator
 * @export
 */
export const SuspiciousTitlesExportDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate a data export for suspicious titles and get Presigned URL for download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspiciousTitlesExportData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/suspiciousTitles/exportData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuspiciousTitlesExportDataApi - functional programming interface
 * @export
 */
export const SuspiciousTitlesExportDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuspiciousTitlesExportDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate a data export for suspicious titles and get Presigned URL for download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suspiciousTitlesExportData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuspiciousTitlesDataExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suspiciousTitlesExportData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuspiciousTitlesExportDataApi - factory interface
 * @export
 */
export const SuspiciousTitlesExportDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuspiciousTitlesExportDataApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate a data export for suspicious titles and get Presigned URL for download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspiciousTitlesExportData(options?: any): AxiosPromise<SuspiciousTitlesDataExport> {
            return localVarFp.suspiciousTitlesExportData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuspiciousTitlesExportDataApi - object-oriented interface
 * @export
 * @class SuspiciousTitlesExportDataApi
 * @extends {BaseAPI}
 */
export class SuspiciousTitlesExportDataApi extends BaseAPI {
    /**
     * 
     * @summary Generate a data export for suspicious titles and get Presigned URL for download
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuspiciousTitlesExportDataApi
     */
    public suspiciousTitlesExportData(options?: AxiosRequestConfig) {
        return SuspiciousTitlesExportDataApiFp(this.configuration).suspiciousTitlesExportData(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuspiciousTitlesOutputApi - axios parameter creator
 * @export
 */
export const SuspiciousTitlesOutputApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all suspicious titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSuspiciousTitles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/suspiciousTitles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuspiciousTitlesOutputApi - functional programming interface
 * @export
 */
export const SuspiciousTitlesOutputApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuspiciousTitlesOutputApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all suspicious titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSuspiciousTitles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuspiciousTitlesOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSuspiciousTitles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuspiciousTitlesOutputApi - factory interface
 * @export
 */
export const SuspiciousTitlesOutputApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuspiciousTitlesOutputApiFp(configuration)
    return {
        /**
         * 
         * @summary List all suspicious titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSuspiciousTitles(options?: any): AxiosPromise<SuspiciousTitlesOutput> {
            return localVarFp.listSuspiciousTitles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuspiciousTitlesOutputApi - object-oriented interface
 * @export
 * @class SuspiciousTitlesOutputApi
 * @extends {BaseAPI}
 */
export class SuspiciousTitlesOutputApi extends BaseAPI {
    /**
     * 
     * @summary List all suspicious titles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuspiciousTitlesOutputApi
     */
    public listSuspiciousTitles(options?: AxiosRequestConfig) {
        return SuspiciousTitlesOutputApiFp(this.configuration).listSuspiciousTitles(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TitleDetailsApi - axios parameter creator
 * @export
 */
export const TitleDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get data for rendering Title Details page
         * @param {string} tconst The unique identifier for the title
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleDetails: async (tconst: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tconst' is not null or undefined
            assertParamExists('titleDetails', 'tconst', tconst)
            const localVarPath = `/title/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tconst !== undefined) {
                localVarQueryParameter['tconst'] = tconst;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TitleDetailsApi - functional programming interface
 * @export
 */
export const TitleDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TitleDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get data for rendering Title Details page
         * @param {string} tconst The unique identifier for the title
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleDetails(tconst: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.titleDetails(tconst, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TitleDetailsApi - factory interface
 * @export
 */
export const TitleDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TitleDetailsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get data for rendering Title Details page
         * @param {string} tconst The unique identifier for the title
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleDetails(tconst: string, options?: any): AxiosPromise<TitleDetailsResponse> {
            return localVarFp.titleDetails(tconst, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TitleDetailsApi - object-oriented interface
 * @export
 * @class TitleDetailsApi
 * @extends {BaseAPI}
 */
export class TitleDetailsApi extends BaseAPI {
    /**
     * 
     * @summary Get data for rendering Title Details page
     * @param {string} tconst The unique identifier for the title
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitleDetailsApi
     */
    public titleDetails(tconst: string, options?: AxiosRequestConfig) {
        return TitleDetailsApiFp(this.configuration).titleDetails(tconst, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TitleRatingsExportRequestApi - axios parameter creator
 * @export
 */
export const TitleRatingsExportRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Request export for ratings data
         * @param {string} tconst The unique identifier for the title
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleRatingsExportRequest: async (tconst: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tconst' is not null or undefined
            assertParamExists('titleRatingsExportRequest', 'tconst', tconst)
            const localVarPath = `/title/ratings/export/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tconst !== undefined) {
                localVarQueryParameter['tconst'] = tconst;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log request for ratings data dump file download
         * @param {TitleRatingsExportLogRequest} titleRatingsExportLogRequest A security logging task for logging ratings data dump files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleRatingsExportRequestLog: async (titleRatingsExportLogRequest: TitleRatingsExportLogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'titleRatingsExportLogRequest' is not null or undefined
            assertParamExists('titleRatingsExportRequestLog', 'titleRatingsExportLogRequest', titleRatingsExportLogRequest)
            const localVarPath = `/title/ratings/export/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(titleRatingsExportLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TitleRatingsExportRequestApi - functional programming interface
 * @export
 */
export const TitleRatingsExportRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TitleRatingsExportRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Request export for ratings data
         * @param {string} tconst The unique identifier for the title
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleRatingsExportRequest(tconst: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleRatingsExportRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.titleRatingsExportRequest(tconst, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Log request for ratings data dump file download
         * @param {TitleRatingsExportLogRequest} titleRatingsExportLogRequest A security logging task for logging ratings data dump files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleRatingsExportRequestLog(titleRatingsExportLogRequest: TitleRatingsExportLogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleRatingsExportLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.titleRatingsExportRequestLog(titleRatingsExportLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TitleRatingsExportRequestApi - factory interface
 * @export
 */
export const TitleRatingsExportRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TitleRatingsExportRequestApiFp(configuration)
    return {
        /**
         * 
         * @summary Request export for ratings data
         * @param {string} tconst The unique identifier for the title
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleRatingsExportRequest(tconst: string, options?: any): AxiosPromise<TitleRatingsExportRequestResponse> {
            return localVarFp.titleRatingsExportRequest(tconst, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log request for ratings data dump file download
         * @param {TitleRatingsExportLogRequest} titleRatingsExportLogRequest A security logging task for logging ratings data dump files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleRatingsExportRequestLog(titleRatingsExportLogRequest: TitleRatingsExportLogRequest, options?: any): AxiosPromise<TitleRatingsExportLogResponse> {
            return localVarFp.titleRatingsExportRequestLog(titleRatingsExportLogRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TitleRatingsExportRequestApi - object-oriented interface
 * @export
 * @class TitleRatingsExportRequestApi
 * @extends {BaseAPI}
 */
export class TitleRatingsExportRequestApi extends BaseAPI {
    /**
     * 
     * @summary Request export for ratings data
     * @param {string} tconst The unique identifier for the title
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitleRatingsExportRequestApi
     */
    public titleRatingsExportRequest(tconst: string, options?: AxiosRequestConfig) {
        return TitleRatingsExportRequestApiFp(this.configuration).titleRatingsExportRequest(tconst, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log request for ratings data dump file download
     * @param {TitleRatingsExportLogRequest} titleRatingsExportLogRequest A security logging task for logging ratings data dump files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitleRatingsExportRequestApi
     */
    public titleRatingsExportRequestLog(titleRatingsExportLogRequest: TitleRatingsExportLogRequest, options?: AxiosRequestConfig) {
        return TitleRatingsExportRequestApiFp(this.configuration).titleRatingsExportRequestLog(titleRatingsExportLogRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TitleRatingsExportStatusApi - axios parameter creator
 * @export
 */
export const TitleRatingsExportStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Query status of export for ratings data
         * @param {string} exportToken The unique identifier for the export
         * @param {number} [limit] Optional arguement for number of rows to be returned from beginning of export.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleRatingsExportStatus: async (exportToken: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportToken' is not null or undefined
            assertParamExists('titleRatingsExportStatus', 'exportToken', exportToken)
            const localVarPath = `/title/ratings/export/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (exportToken !== undefined) {
                localVarQueryParameter['exportToken'] = exportToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TitleRatingsExportStatusApi - functional programming interface
 * @export
 */
export const TitleRatingsExportStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TitleRatingsExportStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Query status of export for ratings data
         * @param {string} exportToken The unique identifier for the export
         * @param {number} [limit] Optional arguement for number of rows to be returned from beginning of export.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleRatingsExportStatus(exportToken: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleRatingsExportStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.titleRatingsExportStatus(exportToken, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TitleRatingsExportStatusApi - factory interface
 * @export
 */
export const TitleRatingsExportStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TitleRatingsExportStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary Query status of export for ratings data
         * @param {string} exportToken The unique identifier for the export
         * @param {number} [limit] Optional arguement for number of rows to be returned from beginning of export.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleRatingsExportStatus(exportToken: string, limit?: number, options?: any): AxiosPromise<TitleRatingsExportStatusResponse> {
            return localVarFp.titleRatingsExportStatus(exportToken, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TitleRatingsExportStatusApi - object-oriented interface
 * @export
 * @class TitleRatingsExportStatusApi
 * @extends {BaseAPI}
 */
export class TitleRatingsExportStatusApi extends BaseAPI {
    /**
     * 
     * @summary Query status of export for ratings data
     * @param {string} exportToken The unique identifier for the export
     * @param {number} [limit] Optional arguement for number of rows to be returned from beginning of export.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitleRatingsExportStatusApi
     */
    public titleRatingsExportStatus(exportToken: string, limit?: number, options?: AxiosRequestConfig) {
        return TitleRatingsExportStatusApiFp(this.configuration).titleRatingsExportStatus(exportToken, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


